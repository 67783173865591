import React, { Component } from 'react';
import ResumeBtn from "./ResumeBtn.js";
import "../../styles/resume.css";

export default class Resume extends Component {
  render() {
    return (
      <>
      <ResumeBtn />
      </>
    )
  }
}
